<template>
    <section class="mx-auto">
        <section class="d-flex flex-wrap justify-space-between">
            <SearchBar 
                :placeholder="'Search course'"
                :value.sync="search" 
                class='mb-3' 
                @clear="clearSearch"
                @search="onSearch"/>
            <v-select
                dense
                solo
                class="custom-border general-custom-field col-xl-3 col-lg-4 roboto f13 secondary-1--text fw500 mb-3"
                :items="classes"
                item-value="class_code"
                v-model="selectedClass"
                hide-details
                :loading="loadingClasses"
                flat
                @change="onClassChange"
            >
                <template slot="item" slot-scope="data">
                    <v-list-item-title class="fw500"><span>{{ data.item.class_code }} - </span>
                        <span class="secondary-3--text">{{ data.item.class_name }}</span>
                    </v-list-item-title>
                </template>
                <template slot="selection" slot-scope="data">
                    <v-list-item-title class="fw500"><span>{{ data.item.class_code }} - </span>
                        <span class="secondary-3--text">{{ data.item.class_name }}</span>
                    </v-list-item-title>
                </template>
            </v-select>
        </section>
        
        <v-data-table
            :loading="loadingCourses"
            :headers="instructor_courses_tbl"
            :items="courses"
            :items-per-page.sync="itemsPerPage"
            class="elevation-1 poppins custom-border border"
            hide-default-footer
        >
            <template v-slot:item.status="{ item }">
                <span v-if="item">{{ item.status }}ED</span>
            </template>

            <template v-slot:item.start="{ item }">
                <span v-if="item">{{ item.available_until?.start_date ? $dateFormat.mmDDyy(item.available_until.start_date) : '' }}</span>
            </template>

            <template v-slot:item.end="{ item }">
                <span v-if="item">{{ item.available_until?.end_date ? $dateFormat.mmDDyy(item.available_until.end_date) : '' }}</span>
            </template>

            <template v-slot:item.action="{ item }">
                <v-btn icon dense color="primary" text class="f12" 
                    @click="$router.push({name: 'Instructor Student Outputs Enrollees', params: {course_uuid: item.uuid}, query: { search: '', page: 1, paginate: 10 }})">
                    <v-icon small class="mr-1" dense>
                        mdi-eye-outline
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <FormPagination 
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginationPaginate"
            @page="onPageChange" 
            @paginate="onPaginateChange"/>
    </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';
import { instructor_courses_tbl } from "@/constants/tblheaders/studentprogress"

export default {
    mixins: [searchAndPaginateMixin],
    data: () => ({
        loadingClasses: false,
        loadingCourses: false,
        instructor_courses_tbl,
        selectedClass: '',
        pageCount: 1,
    }),
    mounted(){
        this.selectedClass = this.$route.query.classes
        if(this.getClass(this.$route.query.classes) === -1) {
            this.selectedClass = ''
            this.filter = {}
        } else {
            this.getData()
        }
    },
    computed: {
        ...mapState("instructor", {
            classes: (state) => {
                return [ {class_name: '', class_code: ''}, ...state.classes ]
            },
            courses: (state) =>  state.courses
        }),

        itemsPerPage(){
            return Number(this.paginate)
        },

        paginationPaginate(){
            return String(this.paginate)
        }
    },
    methods: {
        ...mapActions("instructor", ["getClassesAction", "getStudentOutputCoursesAction"]),
        ...mapMutations(["alertMutation"]),

        onClassChange(e) {
            this.filter = { classes: e };
            this.updateQuery();
        },
        
        getClass(class_code) {
            return this.classes.findIndex((item) => item.class_code === class_code)
        },
        
        getClasses(){
            if(!this.loadingClasses) {
                this.loadingClasses = true
                this.getClassesAction().then(() => {
                    this.loadingClasses = false
                }).catch(() => {
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong',
                        type: "error"
                    })
                    this.loadingClasses = false
                })
            }
        },

        getData(){
            if(!this.loadingCourses && !this.loadingClasses) {
                this.loadingCourses = true
                this.getStudentOutputCoursesAction({ paginate: Number(this.paginate), page: this.page, search: this.search, classes: this.$route.query.classes}).then(res => {
                    this.page = res.current_page
                    this.pageCount = res.last_page
                    this.paginate = String(res.per_page)
                    this.loadingCourses = false
                }).catch(() => {
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong',
                        type: "error"
                    })
                    this.loadingCourses = false
                })
            }
        }
    },
  
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from       
            vm.getClasses();   
        })
    },
}
</script>